import React from 'react';
import TreatmentForm, {TreatmentFormBaseProps} from "./treatment-form";
import useFormFields from "../../Common/use-form-fields";
import {TreatmentListJson} from "../../../api/generated/medical-rest";
import TreatmentService from "../../../services/treatment-service";

interface SimpleNoteFormProps extends TreatmentFormBaseProps {
    prefill?: TreatmentListJson;
}

const SimpleNoteForm: React.FunctionComponent<SimpleNoteFormProps> = (props) => {

    const {formFields, createChangeHandler} = useFormFields<TreatmentListJson>({
        ...TreatmentService.EMPTY_TREATMENT_LIST_JSON,
        ...props.prefill
    });

    return (
        <TreatmentForm onFormSave={props.onFormSave} onFormCancel={props.onFormCancel}
                       onTimestampChange={createChangeHandler("timestamp")}
                       onNoteChange={createChangeHandler("note")} treatment={formFields}>
        </TreatmentForm>

    );
};

export default SimpleNoteForm;
