import React, {FunctionComponent, ReactNode} from 'react';
import {IconButton, Menu, MenuItem, Typography,} from "@mui/material";
import MoreVertIcon from '@mui/icons-material/MoreVert';
import FilterListIcon from '@mui/icons-material/FilterList';
import SortIcon from '@mui/icons-material/Sort';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ActionsMenu from "./actions-menu";
import FlexCard from "../AnimalReview/flex-card";

export enum SortDirection {
    ASC,
    DESC
}

type CardMenuEntry = {
    actionName: string;
    actionFunction: () => void;
};

type QuickActionEntry = {
    icon: ReactNode;
    action: () => void;
}

type SortUpdateFunction = (fieldName: string, newSortDirection: SortDirection) => void;

interface WidgetProperties {
    displayName: string;
    className?: string;
    cardMenuEntries?: CardMenuEntry[];
    quickActions?: QuickActionEntry[];
    onFilterClick?: () => void;
    filterable?: boolean;
    sortableFields?: string [];
    editable?: boolean;
    onSortUpdate?: SortUpdateFunction;
}

const ActionMenu = ({cardMenuEntries}: { cardMenuEntries: CardMenuEntry[];}) => {
    const [actionsAnchorEl, setActionsAnchorEl] = React.useState<null | HTMLElement>(null);

    const handleCardMenuOpen = (event: React.MouseEvent<HTMLElement>) => setActionsAnchorEl(event.currentTarget);
    const handleActionsMenuClose = () => setActionsAnchorEl(null);

    return (
        <>
            <IconButton className="widget-actions-button" aria-label="widget-actions" onClick={handleCardMenuOpen}
                        size="large">
                <MoreVertIcon/>
            </IconButton>
            <ActionsMenu anchor={actionsAnchorEl} actionMenuEntries={cardMenuEntries}
                         onClose={handleActionsMenuClose}/>
        </>
    );
};

const QuickActions = ({quickActions}: { quickActions: QuickActionEntry[];}) => {
    return (
        <>
            {quickActions.map(quickAction => (<>
                    <IconButton className="widget-actions-button" aria-label="widget-actions"
                                onClick={quickAction.action}
                                size="large">
                        {quickAction.icon}
                    </IconButton>
                </>
            ))}
        </>
    );
};

const FilterMenu = (props: { onClick: (() => void) | undefined }) => (
    <IconButton aria-label="filter-options" onClick={props.onClick} size="large">
        <FilterListIcon/>
    </IconButton>
);

const SortMenu = ({sortableFields, onSortUpdate}:{ sortableFields: string[]; onSortUpdate?: SortUpdateFunction}) => {

    const [sorting, setSorting] = React.useState<{ [sortFieldName: string]: SortDirection }>({});

    const [sortAnchorEl, setSortAnchorEl] = React.useState<null | HTMLElement>(null);
    const sortMenuOpen = Boolean(sortAnchorEl);

    const handleSortMenuOpen = (event: React.MouseEvent<HTMLElement>) => setSortAnchorEl(event.currentTarget);
    const handleSortMenuClose = () => setSortAnchorEl(null);

    const handleSortUpdate = (sortableField: string, newSortDirection: SortDirection) => {
        setSorting({[sortableField]: newSortDirection});
        onSortUpdate?.(sortableField, newSortDirection);
    };

    return (
        <>
            <IconButton aria-label="filter-options" onClick={handleSortMenuOpen} size="large">
                <SortIcon/>
            </IconButton>
            <Menu anchorEl={sortAnchorEl} keepMounted open={sortMenuOpen} onClose={handleSortMenuClose}>
                {sortableFields.map((sortableField) => (
                    <MenuItem key={sortableField}>
                        <Typography variant="body1">{sortableField}</Typography>
                        <ToggleButtonGroup size="small" value={sorting[sortableField]} exclusive
                                           onChange={(event, newSortDirection) => handleSortUpdate(sortableField, newSortDirection)}>
                            <ToggleButton value={SortDirection.DESC} aria-label="sort descending">
                                <ArrowDropDownIcon fontSize="small"/>
                            </ToggleButton>
                            <ToggleButton value={SortDirection.ASC} aria-label="sort ascending">
                                <ArrowDropUpIcon fontSize="small"/>
                            </ToggleButton>
                        </ToggleButtonGroup>
                    </MenuItem>
                ))}
            </Menu>
        </>
    );
};

const Widget: FunctionComponent<WidgetProperties> = ({
                                                         displayName,
                                                         cardMenuEntries,
                                                         quickActions,
                                                         onFilterClick,
                                                         filterable,
                                                         editable= true,
                                                         sortableFields,
                                                         onSortUpdate,
                                                         children,
                                                         className = ""
                                                     }) => {

    const actionsMenu = cardMenuEntries
        ? <ActionMenu cardMenuEntries={cardMenuEntries} />
        : null;

    const quickActionButtons = quickActions
        ? <QuickActions quickActions={quickActions}/>
        : null;

    const sortMenu = sortableFields
        ? <SortMenu sortableFields={sortableFields} onSortUpdate={onSortUpdate}/>
        : null;

    const filterMenu = filterable
        ? <FilterMenu onClick={onFilterClick}/>
        : null;

    return (
        <FlexCard title={displayName} headerActions={editable && <>{filterMenu}{sortMenu}{quickActionButtons}{actionsMenu}</>}>
            {children}
        </FlexCard>
    );
};

export default Widget;