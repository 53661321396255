// @flow
import React, {Fragment, FunctionComponent} from 'react';
import {Avatar, Divider, ListItemAvatar, ListItemButton, ListItemText} from "@mui/material";
import {useHistory} from "react-router-dom";
import RouteService from "../../services/route-service";
import {RegistrationRequestController$PendingRequestJson} from "../../api/generated/registry-rest";

interface ReviewRequestListItemProps {
    application: RegistrationRequestController$PendingRequestJson
}

const ReviewRequestListItem: FunctionComponent<ReviewRequestListItemProps> = ({application}) => {

    const history = useHistory();

    const navigateToReview = () => {
        const expanded = RouteService.expand(RouteService.REGISTRY_ANIMAL_REVIEW, {id: application.id});
        history.push(expanded);
    }


    return (
        <Fragment>
            <ListItemButton onClick={navigateToReview}>
                <ListItemAvatar>
                    <Avatar  src="./alpaca_placeholder" />
                </ListItemAvatar>
                <ListItemText primary={`${application.animal.name}`} secondary={`Züchter: ${application.breeder.name}`} />
            </ListItemButton>
            <Divider variant="middle" component="li"/>
        </Fragment>
    );

};

export default ReviewRequestListItem