import React from 'react';
import {SxProps} from "@mui/material";
import FormControl from "@mui/material/FormControl";
import Input from "@mui/material/Input";
import InputAdornment from "@mui/material/InputAdornment";
import FormHelperText from "@mui/material/FormHelperText";
import TreatmentForm, {TreatmentFormBaseProps} from "./treatment-form";
import useFormFields from "../../Common/use-form-fields";
import {TreatmentListJson} from "../../../api/generated/medical-rest";
import TreatmentService from "../../../services/treatment-service";

interface SimpleNumberValueFormProps extends TreatmentFormBaseProps {
    prefill?: TreatmentListJson;
    valueFormHelperText: string;
}

const styles: Record<string, SxProps> = {
    weightInputFormControl: {
        marginBottom: 1
    }
} as const;

const SimpleNumberValueForm: React.FunctionComponent<SimpleNumberValueFormProps> = (props) => {

    const {formFields, createChangeHandler} = useFormFields<TreatmentListJson>({
        ...TreatmentService.EMPTY_TREATMENT_LIST_JSON,
        ...props.prefill
    });

    const isValidNumber = String(+formFields.value) === String(formFields.value);

    return (
        <TreatmentForm onFormSave={props.onFormSave}
                       onFormCancel={props.onFormCancel}
                       onTimestampChange={createChangeHandler("timestamp")}
                       onNoteChange={createChangeHandler("note")}
                       hasError={!isValidNumber}
                       treatment={formFields}>
            <FormControl sx={styles.weightInputFormControl} error={!isValidNumber}>
                <Input
                    id="treatment-value-input"
                    value={formFields.value}
                    type="number"
                    required
                    onChange={createChangeHandler("value")}
                    endAdornment={<InputAdornment position="end">kg</InputAdornment>}
                    aria-describedby="treatment-value-input-helper-text"
                    aria-label='treatment value'
                    inputProps={{
                        min: 0,
                    }}
                />
                <FormHelperText id="treatment-value-input-helper-text">
                    {isValidNumber ? props.valueFormHelperText : 'Ungültiger Wert!'}
                </FormHelperText>
            </FormControl>
        </TreatmentForm>

    );
};

export default SimpleNumberValueForm;
