import React, {ChangeEvent, useState} from "react";
import {SelectChangeEvent} from "@mui/material";

export type FormFieldValueTransformer = (value: any) => any;

const useFormFields = <T>(initialValues: T) => {
    const [formFields, setFormFields] = useState(initialValues);
    const createChangeHandler = (key: keyof T, valueTransformer?: FormFieldValueTransformer) => (e: React.ChangeEvent<HTMLInputElement> | ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | SelectChangeEvent | any) => {
        const rawValue = e?.target?.value ?? e;
        const value = valueTransformer ? valueTransformer(rawValue) : rawValue;
        setFormFields((prev: T) => ({...prev, [key]: value}));
    };
    return {formFields, createChangeHandler};
}

export default useFormFields;