import React from 'react';
import {RouteComponentProps, withRouter} from 'react-router-dom';
import BaseData from './base-data-widget';
import TREATMENT_TYPES from "../../api/dtos/treatment-type";
import Tags from "./Tags";
import TreatmentList from "./TreatmentList";
import PregnancyWidget from "./Pregnancy/pregnancy-widget";
import RenderOnSex from "../Common/render-on-sex";
import MatingWidget from "./Pregnancy/mating-widget";
import RegistryWidget from "../Registry/registry-widget";
import {AnimalService} from "../../services/animal-service";
import {JsonAnimal} from "../../api/generated/rest-dto";
import TreatmentService from "../../services/treatment-service";
import {TreatmentListJson} from "../../api/generated/medical-rest";
import {PregnancyService} from "../../services/pregnancy-service";
import {AnimalRecordStatusValues, SexValues} from "../../api/generated/herd-animal";
import PedigreeWidget from "./Pedigree/pedigree-widget";
import FlexStack from "../Common/flex-stack";
import WeightChart from "./WeightChart";
import {DateTime} from "luxon";
import IdentificationsWidget from "./identifications-widget";
import {AnimalBaseData, transformFromJsonAnimal} from "../../api/dtos/animal-base-data";
import {useGlobalSnackbarStore} from "../../stores/global-snackbar-store";
import {useAnimal} from "../Common/hooks/use-animal";
import {useQuery} from "react-query";
import {useRegistrations} from "../Common/hooks/use-registrations";
import {LinearProgress, Skeleton} from "@mui/material";
import {Widget} from '../Common';

const pregnancyTreatmentTypes = [TREATMENT_TYPES.spittest, TREATMENT_TYPES.ultrasound].map(String);
const isPregnancyTreatment = (t: TreatmentListJson) => pregnancyTreatmentTypes.includes(t.type);

const useTreatments = (animal: JsonAnimal | undefined) => {
    const {data, isLoading, isError, refetch} = useQuery({
        queryKey: ['treatments', animal?.id],
        queryFn: () => TreatmentService.loadTreatmentsForAnimalId(animal!.id),
        enabled: !!animal,
    });

    return {
        treatments: data,
        refetch,
        isLoading,
        isError,
    };
};

const usePregnancies = (animal: JsonAnimal | undefined) => {
    const {data, isLoading, isError, refetch} = useQuery({
        queryKey: ['pregnancies', animal?.panonIdentifier.id],
        queryFn: () => PregnancyService.listByParent(animal!.panonIdentifier.id),
        enabled: !!animal,
    });

    return {
        pregnancies: data,
        isLoading,
        isError,
        refetch
    };
};

type AnimalDashboardProps = {
    panonId: string
};

const AnimalDashboard: React.FC<RouteComponentProps<AnimalDashboardProps>> = ({match}) => {

    const {error, success} = useGlobalSnackbarStore((state) => state);

    const {animal, isLoading} = useAnimal(match.params.panonId);
    const {treatments, isLoading: treatmentsLoading, refetch: refetchTreatments} = useTreatments(animal);
    const {pregnancies, isLoading: pregnanciesLoading, refetch: refetchPregnancies} = usePregnancies(animal);
    const {registrations: animalRegistrations, isLoading: registrationsLoading} = useRegistrations(animal?.panonIdentifier.id);

    if (isLoading || treatmentsLoading || pregnanciesLoading || registrationsLoading) {
        return (
            <>
                <LinearProgress/>
                <FlexStack spacing={3}>
                    <Widget displayName="Stammdaten">
                        <Skeleton/>
                        <Skeleton/>
                        <Skeleton/>
                        <Skeleton/>
                    </Widget>
                </FlexStack>
            </>
        );
    }

    if (!animal || !treatments || !pregnancies || !animalRegistrations) {
        return null;
    }

    const timestampedTreatments = treatments.filter(t => t.timestamp !== null);
    const animalIsRegistered = animalRegistrations.length !== 0;

    const deleteTreatment = async (treatmentId: string) => {
        if (treatmentId) {
            await TreatmentService.deleteTreatment(treatmentId);
            await refetchTreatments();
        }
    }

    const provideWeights2 = () => {
        return timestampedTreatments
            .filter(t => t.type === TREATMENT_TYPES.weighing)
            .map(weighing => ({
                date: weighing.timestamp as DateTime,
                dateString: weighing.timestamp.toLocaleString(),
                weight: weighing.value,
                note: weighing.note,
            }));
    };

    const provideBodyScores = () => {
        return timestampedTreatments
            .filter(t => t.type === TREATMENT_TYPES.bodyscore)
            .map(scoring => ({
                date: scoring.timestamp as DateTime,
                dateString: scoring.timestamp.toLocaleString(),
                bodyscore: scoring.value,
                note: scoring.note,
            }));
    };

    const handlePregnancyDelete = async (pregnancyId: string) => {
        await PregnancyService.deletePregnancy(pregnancyId);
        await refetchPregnancies();
    }

    const handleBaseDataSubmit = async (update: AnimalBaseData) => {
        try {
            await AnimalService.updateBaseData(animal, update);
            success("Daten gespeichert!");
        } catch (e) {
            error("Aktualisierung der Stammdaten fehlgeschlagen!");
        }
    }

    const nonPregnancyTreatments = treatments.filter(t => !isPregnancyTreatment(t));
    const pregnancyTreatments = treatments.filter(t => isPregnancyTreatment(t));

    return (
        <FlexStack spacing={3}>
            <Tags animal={animal}/>
            <BaseData onSubmit={handleBaseDataSubmit} initialBaseData={transformFromJsonAnimal(animal)} deactivatable={animal.recordStatus === AnimalRecordStatusValues.HERD}/>
            <IdentificationsWidget animal={animal} editExistingAllowed={!animalIsRegistered} />
            <RegistryWidget animal={animal} animalRegistrations={animalRegistrations} />
            <WeightChart animal={animal} weights={provideWeights2()} bodyscores={provideBodyScores()}/>
            <TreatmentList animal={animal} treatments={nonPregnancyTreatments} onTreatmentDelete={deleteTreatment}/>
            <RenderOnSex requiredSex={SexValues.FEMALE} animal={animal}>
                <PregnancyWidget
                    animal={animal}
                    pregnancies={pregnancies}
                    treatments={pregnancyTreatments}
                    onDelete={handlePregnancyDelete}
                    onTreatmentDelete={deleteTreatment} />
            </RenderOnSex>
            <RenderOnSex requiredSex={SexValues.MALE} animal={animal}>
                <MatingWidget animal={animal} matings={pregnancies}/>
            </RenderOnSex>
            <PedigreeWidget animal={animal} isRegistered={animalIsRegistered}/>
        </FlexStack>
    );

}

export default withRouter(AnimalDashboard);
