import ReviewSimilaritiesComparisonDto, {ComparisonPair} from "../api/dtos/review-similarities-comparison-dto";
import {
    RegistrationRequestApi,
    RegistrationRequestController$PendingRequestJson,
    RequestRegistrationApi,
    RequestRegistrationController$AnimalSimilarityJson
} from "../api/generated/registry-rest";
import ApiConfig from "../api/config/api-config";
import {RegistryService} from "./registry-service";
import {JsonAnimal} from "../api/generated/rest-dto";
import {RegistrationService} from "./registration-service";

const apiConfig = new ApiConfig();
const registrationRequestApi = new RegistrationRequestApi(apiConfig);
const requestRegistrationApi = new RequestRegistrationApi(apiConfig);


const loadReviewRequestsByWarden = async (): Promise<RegistrationRequestController$PendingRequestJson[]> => {
    const registries = await RegistryService.findAllWhereBreederIsWarden();
    const listResults = await Promise.all(registries.map(r => registrationRequestApi.list(r.id)));
    return listResults.flatMap(lr => lr.items);
}

const addPairIfValuesAreEqual = (pairsArray: ComparisonPair[], displayName: string, reviewedValue: any, similarValue: any) => {
    if (reviewedValue === similarValue) {
        pairsArray.push(new ComparisonPair(displayName, reviewedValue, similarValue));
    }
}

const loadSimilarityComparison = async (reviewedAnimal: JsonAnimal, similarAnimal: JsonAnimal): Promise<ReviewSimilaritiesComparisonDto> => {
    const compPairs: ComparisonPair[] = [];
    addPairIfValuesAreEqual(compPairs, "Herdkürzel", reviewedAnimal.herdCode, similarAnimal.herdCode);
    addPairIfValuesAreEqual(compPairs, "Name", reviewedAnimal.name, similarAnimal.name);
    addPairIfValuesAreEqual(compPairs, "Geschlecht", reviewedAnimal.sex, similarAnimal.sex);
    addPairIfValuesAreEqual(compPairs, "Geburtsdatum", reviewedAnimal.dateOfBirth.toLocaleString(), similarAnimal.dateOfBirth.toLocaleString());
    addPairIfValuesAreEqual(compPairs, "Farbe", reviewedAnimal.fiberColor, similarAnimal.fiberColor);
    addPairIfValuesAreEqual(compPairs, "Rasse", reviewedAnimal.breed, similarAnimal.breed);
    addPairIfValuesAreEqual(compPairs, "Chip", reviewedAnimal.panonIdentifier.animalIdentifiers[0]?.value, similarAnimal.panonIdentifier.animalIdentifiers[0].value);

    return {
        comparisonPairs: compPairs,
        avatarUrlReviewAnimal: "/camille2.jpg",
        avatarUrlSimilarAnimal: "/camille.jpg"
    }
}

const rejectReview = async (requestId: string, comment: string) => {
    await requestRegistrationApi.reject(requestId, comment);
}

const acceptReview = async (requestId: string) => {
    await requestRegistrationApi.acceptRequest(requestId);
}

const findSimilarAnimals = async (reviewRequestId: string): Promise<RequestRegistrationController$AnimalSimilarityJson[]> => {
    return (await requestRegistrationApi.findSimilarAnimals(reviewRequestId)).items;
}

const findRegisteredSimilarAnimals = async (reviewRequestId: string): Promise<RequestRegistrationController$AnimalSimilarityJson[]> => {
    const similarAnimals = await findSimilarAnimals(reviewRequestId);
    const filtered = [];
    for (const sa of similarAnimals) {
        if ((await RegistrationService.findRegistrationsByPanonId(sa.panonIdentifier)).length > 0) {
            filtered.push(sa);
        }
    }
    return filtered;

}

const RegistrationReviewService = {
    loadReviewRequestsByWarden,
    loadSimilarityComparison,
    findSimilarAnimals,
    findRegisteredSimilarAnimals,
    rejectReview,
    acceptReview
};

export default RegistrationReviewService;