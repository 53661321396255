import React from "react";
import {useQuery} from "react-query";
import {PregnancyService} from "../services/pregnancy-service";
import {ErrorBox} from "../components/Common/error-box";
import {
    Alert,
    Avatar,
    LinearProgress,
    ListItem,
    ListItemAvatar,
    ListItemButton,
    ListItemText,
    Stack
} from "@mui/material";
import List from "@mui/material/List";
import {PregnancyListJson} from "../api/generated/medical-rest";
import {JsonAnimal} from "../api/generated/rest-dto";
import PregnancyDateRange from "../components/AnimalDashboard/Pregnancy/pregnancy-date-range";
import FlexStack from "../components/Common/flex-stack";
import {LoadingAvatarListItem} from "../components/Common/loading-avatar-list-item";
import Typography from "@mui/material/Typography";
import RouteService from "../services/route-service";
import {Link as RouterLink} from "react-router-dom";
import {AnimalService} from "../services/animal-service";

export const useHerdPregnancies = () => {
    const {data, isLoading, isError} = useQuery({
        queryKey: ['herd-pregnancies'],
        queryFn: () => PregnancyService.listActive(),
    });

    return {
        pregnancies: data,
        isLoading,
        isError,
    }
};

const useParents = (pregnancies: PregnancyListJson[] = []) => {
    const {data, isLoading, isError} = useQuery({
        queryKey: ['parents', pregnancies.map(p => p.id)],
        queryFn: async () => {
            const parentIds = Array.from(new Set(pregnancies.flatMap(p => [p.damId, p.sireId]))).filter((p): p is string => !!p);
            const parentPromises = parentIds.map(panonId => AnimalService.loadJsonAnimalByPanonId(panonId));
            const parents = (await Promise.all(parentPromises)).filter((p): p is JsonAnimal => !!p);
            return new Map(parents.map(parent => [parent.panonIdentifier.id, parent]));
        },
        enabled: !!pregnancies.length
    });

    return {
        parents: data ?? new Map<string, JsonAnimal>(),
        isLoading,
        isError,
    };
};

const PregnancyListItem = (props: { pregnancy: PregnancyListJson, parents: Map<string, JsonAnimal> }) => {
    const {pregnancy, parents} = props;

    const fullName = (animal: JsonAnimal | undefined) => animal ? `${animal.herdCode} ${animal.name}` : '';

    const dam = parents.get(pregnancy.damId);
    const sire = !!pregnancy.sireId && parents.get(pregnancy.sireId);

    return (
        <ListItem>
            <ListItemButton component={RouterLink}
                            to={{pathname: RouteService.expand(RouteService.PREGNANCY_DETAILS, {pregnancyId: pregnancy.id})}}>
                <ListItemAvatar>
                    <Avatar alt={fullName(dam)}/>
                </ListItemAvatar>
                <ListItemAvatar>
                    <Avatar alt={sire ? fullName(sire) : ''}/>
                </ListItemAvatar>
                <ListItemText>
                    <Stack>
                        <PregnancyDateRange pregnancy={pregnancy}/>
                        <Typography>Stute: {fullName(dam)}</Typography>
                        <Typography>Hengst: {sire ? fullName(sire) : '---'}</Typography>
                    </Stack>
                </ListItemText>
            </ListItemButton>
        </ListItem>
    );
};

export const HerdPregnancies = () => {
    const {pregnancies, isLoading: isPregnanciesLoading, isError: isPregnanciesError} = useHerdPregnancies();
    const {parents, isLoading: isParentsLoading, isError: isParentsError} = useParents(pregnancies);

    const isError = isPregnanciesError || isParentsError;
    const isLoading = isPregnanciesLoading || isParentsLoading;

    if (isLoading) {
        return (
            <FlexStack>
                <LinearProgress/>

                <List>
                    <LoadingAvatarListItem/>
                    <LoadingAvatarListItem/>
                    <LoadingAvatarListItem/>
                </List>
            </FlexStack>
        );
    }

    if (isError) {
        return <ErrorBox>Fehler beim Laden der Trächtigkeiten</ErrorBox>;
    }

    if (!pregnancies?.length) {
        return <Alert severity="info">Keine trächtigen Tiere in der Herde</Alert>;
    }

    return (
        <FlexStack>
            <List>
                {pregnancies.map(pregnancy =>
                    <PregnancyListItem key={pregnancy.id}
                                       parents={parents}
                                       pregnancy={pregnancy}/>)}
            </List>
        </FlexStack>
    );
};