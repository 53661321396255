import Divider from "@mui/material/Divider";
import List from "@mui/material/List";
import ListItemLink from "./list-item-link";
import RenderOnAuthenticated from "./Permissions/render-on-authenticated";
import * as PropTypes from "prop-types";
import React, {FunctionComponent} from "react";
import {Box, Collapse, ListItemButton, ListItemText, SxProps, Toolbar} from "@mui/material";
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import RouteService from "../services/route-service";
import RenderOnRole from "./Permissions/render-on-role";
import {RenderOnFeatureFlag} from "./Common/render-on-feature-flag";
import {AnimalRecordStatusValues} from "../api/generated/herd-animal";
import {PANON_ADMIN, REGISTRY_WARDEN} from "./Permissions/Roles";

interface MenuProperties {
    onClick: () => void
    sx: SxProps
}

const NavigationMenu: FunctionComponent<MenuProperties> = ({onClick, sx}) => {

    const [openAdminArea, setOpenAdminArea] = React.useState(true);

    const handleAdminAreaClick = () => {
        setOpenAdminArea(!openAdminArea);
    };

    return <Box sx={sx}>
        <Toolbar/>
        <Divider/>
        <List component="nav">
            <ListItemLink onClickHandler={onClick} to={RouteService.HOME} primary="Home"/>
            <RenderOnAuthenticated>
                <ListItemLink
                    onClickHandler={onClick}
                    to={RouteService.expand(RouteService.HERD_GROUP, {groupId: "all", recordStatus: AnimalRecordStatusValues.HERD})}
                    primary="Meine Herde"/>
                <List disablePadding>
                    <ListItemLink
                        sx={{ pl: 4 }}
                        onClickHandler={onClick}
                        to={RouteService.expand(RouteService.HERD_GROUP, {groupId: "all", recordStatus: AnimalRecordStatusValues.HERD})}
                        primary="Herde"/>
                    <ListItemLink
                        sx={{ pl: 4 }}
                        onClickHandler={onClick}
                        to={RouteService.HERD_PREGNANCIES_LIST}
                        primary="Trächtigkeiten"/>
                    <ListItemLink
                        sx={{ pl: 4 }}
                        onClickHandler={onClick}
                        to={RouteService.expand(RouteService.HERD_GROUP, {groupId: "all", recordStatus: AnimalRecordStatusValues.INACTIVE})}
                        primary="Inaktive Tiere"/>
                </List>

                <ListItemLink onClickHandler={onClick} to={RouteService.REGISTRY_ANIMAL_SEARCH} primary="Register"/>
                <List disablePadding>
                    <ListItemLink sx={{ pl: 4 }} onClickHandler={onClick} to={RouteService.REGISTRY_ANIMAL_SEARCH} primary="Tiersuche"/>
                    <RenderOnRole roles={[REGISTRY_WARDEN, PANON_ADMIN]}>
                        <ListItemLink sx={{ pl: 4 }} onClickHandler={onClick} to={RouteService.REGISTRY_SEARCH} primary="Registeradministration"/>
                        <ListItemLink sx={{ pl: 4 }} onClickHandler={onClick} to={RouteService.REGISTRY_ANIMAL_REVIEW_LIST} primary="Registeranmeldungen"/>
                    </RenderOnRole>
                </List>

                <ListItemLink onClickHandler={onClick} to={RouteService.BREEDERS_LIST} primary="Züchter"/>

                <RenderOnFeatureFlag  featureFlag={"ADMIN_AREA"}>
                    <RenderOnRole roles={[PANON_ADMIN]}>
                        <ListItemButton onClick={handleAdminAreaClick}>
                            <ListItemText primary="Admin Area" />
                            {openAdminArea ? <ExpandLess /> : <ExpandMore />}
                        </ListItemButton>
                        <Collapse in={openAdminArea} timeout="auto" unmountOnExit>
                            <List component="div" disablePadding>
                                <ListItemLink sx={{ pl: 4 }} onClickHandler={onClick} to={RouteService.ADMIN_LINK_ACCOUNT_WITH_BREEDER} primary="Accounts Verlinken"/>
                            </List>
                        </Collapse>
                    </RenderOnRole>
                </RenderOnFeatureFlag>
            </RenderOnAuthenticated>
        </List>
    </Box>;
}

NavigationMenu.propTypes = {
    onClick: PropTypes.func.isRequired
};

export default NavigationMenu;
