// @flow
import React, {Suspense} from 'react';
import {Alert, List} from "@mui/material";
import RegistrationReviewService from "../../services/registration-review-service";
import ReviewRequestListItem from "./review-request-list-item";
import {useQuery} from "react-query";
import {LoadingAvatarListItem} from "../Common/loading-avatar-list-item";
import {withErrorBoundary} from "react-error-boundary";
import {ErrorBox} from "../Common/error-box";

const useReviewApplications = () => {
    const {data} = useQuery({
        queryKey: ['review-applications'],
        queryFn: async () => RegistrationReviewService.loadReviewRequestsByWarden(),
        suspense: true,
    });

    return {applications: data ?? []};
};

const ReviewListView = () => {
    const {applications} = useReviewApplications();

    if (!applications.length) {
        return <Alert severity="info">Keine Anfragen vorhanden</Alert>;
    }

    return (
        <List>
            {applications.map(a => <ReviewRequestListItem key={a.id} application={a}/>)}
        </List>
    );
};

const ReviewList = () => (
    <Suspense fallback={<List><LoadingAvatarListItem/><LoadingAvatarListItem/><LoadingAvatarListItem/></List>}>
        <ReviewListView/>
    </Suspense>
);

export default withErrorBoundary(
    ReviewList,
    {
        fallback: <ErrorBox>Fehler beim Rendern der Anfragen!</ErrorBox>
    }
);