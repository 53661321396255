import {Button, Card, CardContent, Link, Typography} from "@mui/material";
import AuthService from "../services/auth-service";
import RouteService from "../services/route-service";
import {Link as RouterLink} from 'react-router-dom';
import Divider from "@mui/material/Divider";

export const LoginCard = () => {
    return (
        <Card sx={{width: '360px', my: 6, textAlign: 'center'}}>
            <CardContent>
                <Typography variant="h5" sx={{my: 1}}>Willkommen bei Alpacuna Herds!</Typography>

                <Button variant="contained" size="large" fullWidth onClick={() => AuthService.doLogin()}>
                    <Typography fontWeight="bold">Login</Typography>
                </Button>

                <Divider variant="middle" sx={{m: 2}}/>

                <Typography>
                    Noch kein Account? <Link component={RouterLink} to={RouteService.BREEDER_SIGNUP}>
                    Hier registrieren
                </Link>.
                </Typography>
            </CardContent>
        </Card>
    );
};