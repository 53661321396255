import React, {FunctionComponent, useState} from 'react';
import Button from '@mui/material/Button';
import {Box, SxProps, Theme} from "@mui/material";
import TextField from "@mui/material/TextField";
import {DateTime} from "luxon";
import {JsonAnimal} from "../api/generated/rest-dto";
import {CreatePregnancyJson} from "../api/generated/medical-rest";
import useFormFields from "../components/Common/use-form-fields";
import {useHistory, useLocation} from "react-router-dom";
import FlexStack from "../components/Common/flex-stack";
import {PregnancyService} from "../services/pregnancy-service";
import RouteService from "../services/route-service";
import {SexValues} from "../api/generated/herd-animal";
import {DatePicker} from "@mui/x-date-pickers";
import ParentPicker from "../components/Pedigree/parent-picker";
import {useGlobalSnackbarStore} from "../stores/global-snackbar-store";


interface PregnancyLocationState {
    mother: JsonAnimal
}

const styles: Record<string, SxProps<Theme>> = {
    button: {
        margin: 1
    }
};

const PregnancyCreate: FunctionComponent = () => {

    const defaultCalcEndDate = DateTime.now().plus({day: 350});
    const defaultPregnancy: CreatePregnancyJson = {
        actualEndDate: null,
        calculatedEndDate: defaultCalcEndDate,
        fatherAnimalId: "",
        sireId: "",
        damId: "",
        motherAnimalId: "",
        note: "",
        startDate: DateTime.now()
    };

    const {error, success} = useGlobalSnackbarStore((state) => state);

    const history = useHistory();
    const { state: locationState } = useLocation<PregnancyLocationState>();

    const {formFields, createChangeHandler} = useFormFields({...defaultPregnancy});
    const [selectedSire, setSelectedSire] = useState<JsonAnimal | undefined>()

    //Guard against direct calls of the page without any locationState.
    if (!(locationState?.mother)) {
        history.push(RouteService.HOME);
        return null;
    }

    const mother: JsonAnimal = locationState.mother;
    const sourceRoute = RouteService.expand(RouteService.ANIMAL_DETAILS, {panonId: mother.panonIdentifier.id});

    const handleCreate = async () => {
        await PregnancyService.createPregnancy({...formFields, motherAnimalId: mother.panonIdentifier.id});
        success("Trächtigkeit wurde angelegt!", sourceRoute);
    };

    const generatedStartDateHandler = createChangeHandler("startDate");
    const generatedCalcEndDateHandler = createChangeHandler("calculatedEndDate");
    const startDateChangeHandler = (date: DateTime | null) => {
        if (date) {
            generatedStartDateHandler(date);
            generatedCalcEndDateHandler(date.plus({days: 350}));
        }
    }

    const handleSireSelected = (selectedSire: JsonAnimal) => {
        if(selectedSire.sex === SexValues.MALE) { //just to make sure we're setting a male as sire
            formFields.fatherAnimalId = selectedSire.panonIdentifier.id;
            setSelectedSire(selectedSire);
        } else {
            error('Das Geschlecht des Deckhengstes muss "Männlich" sein!');
        }
    }

    return (
        <FlexStack>
            <DatePicker
                inputFormat="MM/dd/yyyy"
                label="Trächtigkeitsbeginn"
                value={formFields.startDate}
                onChange={startDateChangeHandler}
                renderInput={(params: any) => <TextField {...params} />}

            />

            <DatePicker
                disabled
                inputFormat="MM/dd/yyyy"
                label="Errechneter Geburtstermin"
                value={formFields.calculatedEndDate}
                onChange={generatedCalcEndDateHandler}
                renderInput={(params: any) => <TextField {...params} />}
            />

            <DatePicker
                inputFormat="MM/dd/yyyy"
                label="Tatsächlicher Geburtstermin"
                value={formFields.actualEndDate}
                onChange={createChangeHandler("actualEndDate")}
                renderInput={(params: any) => <TextField {...params} />}
            />
            <TextField
                id="note-textarea"
                label="Notiz"
                placeholder="Notiz"
                value={formFields.note}
                onChange={createChangeHandler("note")}
                multiline
            />
            <ParentPicker onParentSelected={handleSireSelected} damEditable={false} dam={mother} sire={selectedSire}/>
            <Box>
                <Button sx={styles.button} onClick={() => history.push(sourceRoute)} variant="contained"
                        color="primary">
                    Abbrechen
                </Button>
                <Button sx={styles.button} onClick={handleCreate} variant="contained" color="primary">
                    Speichern
                </Button>
            </Box>
        </FlexStack>
    );
};

export default PregnancyCreate;
