import i18next from "i18next";
import {initReactI18next} from "react-i18next";

const resources = {
    de: {
        translation: {
            breeders: {
                filter: 'Züchter suchen'
            },
            treatmentType: {
                weighing: "Wiegung",
                othermedication: "Sonstige Medikation",
                other: "Sonstige Behandlung",
                spittest: "Spucktest",
                ultrasound: "Ultraschall",
                vaccination: "Impfung",
                bodyscore: "Bodyscore",
                vitaminsandminerals: "Vitamine und Mineralstoffe",
                antiparasitics: "Parasitenbehandlung"
            },
            herd: {
                filter: "Herde filtern"
            },
            breeder_create_success: "Konto erfolgreich angelegt",
            registered_at: "Registriert bei"
        },
    }
};



i18next
    .use(initReactI18next)
    .init({
        resources,
        lng: "de",
        interpolation: {
            escapeValue: false,
        },
    });

export default i18next;