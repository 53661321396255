import React, {FunctionComponent} from 'react';
import {InputLabel, MenuItem, Select, SelectChangeEvent} from "@mui/material";
import TreatmentType from "../../../api/dtos/treatment-type";
import FormControl from "@mui/material/FormControl";
import {useTranslation} from "react-i18next";

interface TreatmentTypeSelectProps {
    label: string;
    type?: TreatmentType;
    onTypeChange: (newType: TreatmentType) => void
    availableOptions: TreatmentType[];
}

const TreatmentTypeSelect: FunctionComponent<TreatmentTypeSelectProps> = (props) => {
    const {availableOptions, label, type} = props;
    const {t} = useTranslation();

    return (
        <FormControl variant="standard" fullWidth>
            <InputLabel id="select-medication-type-label">{label}</InputLabel>
            <Select
                labelId="select-medication-type-label"
                id="medication-type-select"
                value={type}
                onChange={(event: SelectChangeEvent) => props.onTypeChange(event.target.value as TreatmentType)}
            >
                {availableOptions.map(option =>  <MenuItem key={option} value={option}>{t(option)}</MenuItem>)}
            </Select>
        </FormControl>
    );
};

export default TreatmentTypeSelect;