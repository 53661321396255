// @flow
import React, {FunctionComponent, useEffect, useState} from 'react';
import {Box, Button} from "@mui/material";
import TabularTextCard from "./tabular-text-card";
import {useParams} from "react-router-dom";
import RegistrationReviewService from "../../services/registration-review-service";
import ExistingRegistrationsCard from "./existing-registrations-card";
import ReviewSimilaritiesCard from "./review-similarities-card";
import CommentCard from "./comment-card";
import OnCloseNavigationSnackbar from "../Common/on-close-navigation-snackbar";
import RouteService from "../../services/route-service";
import FlexStack from "../Common/flex-stack";
import {AnimalService} from "../../services/animal-service";
import {RegistrationService} from "../../services/registration-service";
import {
    RegistrationRequestController$PendingRequestJson,
    RegistrationsController$RegistrationJson,
    RequestRegistrationController$AnimalSimilarityJson
} from "../../api/generated/registry-rest";
import {JsonAnimal} from "../../api/generated/rest-dto";
import {AlpacaFiberColorValues, AnimalRecordStatusValues, SexValues} from "../../api/generated/herd-animal";
import {DateTime} from "luxon";
import PedigreeCard from "../AnimalDashboard/Pedigree/pedigree-card";

interface AnimalReviewPathParams {
    id: string;
}

const AnimalReview: FunctionComponent = () => {

    const ACCEPTED_MESSAGE: string = "Registrierung akzeptiert";
    const REJECTED_MESSAGE: string = "Registrierung abgelehnt";

    const [showSnackbar, setShowSnackbar] = useState<boolean>(false);
    const [snackbarMessage, setSnackbarMessage] = useState<string>("");
    const [comment, setComment] = useState<string>("")

    const [reviewRequest, setReviewRequest] = useState<RegistrationRequestController$PendingRequestJson>();
    const [animal, setAnimal] = useState<JsonAnimal>({
        breed: "",
        chipId: "",
        dateOfBirth: DateTime.now(),
        fiberColor: AlpacaFiberColorValues.UNKNOWN,
        herdCode: "",
        id: "",
        name: "",
        ownerId: "",
        panonIdentifier: {animalIdentifiers: [], id: ""},
        recordStatus: AnimalRecordStatusValues.PEDIGREE,
        sex: SexValues.UNKNOWN,
        tags: []
    });
    const [similarAnimals, setSimilarAnimals] = useState<RequestRegistrationController$AnimalSimilarityJson[]>([]);
    const [registrations, setRegistrations] = useState<RegistrationsController$RegistrationJson[]>([]);

    const reviewRequestId: string = useParams<AnimalReviewPathParams>().id;

    const OUTCOME_ACCEPTED = "accepted";
    const OUTCOME_REJECTED = "rejected";

    useEffect(() => {
        const initialize = async () => {
            const reviewRequest = await RegistrationService.getPendingRequest(reviewRequestId);
            const similarAnimals = await RegistrationReviewService.findRegisteredSimilarAnimals(reviewRequestId);
            const animal = await AnimalService.loadJsonAnimalByPanonId(reviewRequest.animal.id); //animal.id is a panonId
            const registrations = await RegistrationService.findRegistrationsByPanonId(reviewRequest.animal.id);

            setReviewRequest(reviewRequest);
            setSimilarAnimals(similarAnimals);
            setAnimal(animal);
            setRegistrations(registrations);
        }

        initialize();
    }, [reviewRequestId])

    const handleAcceptReject = async (outcome: string) => {
        if (reviewRequest) {
            if (outcome === OUTCOME_ACCEPTED) {
                setSnackbarMessage(ACCEPTED_MESSAGE);
                await RegistrationReviewService.acceptReview(reviewRequestId);
            } else if (outcome === OUTCOME_REJECTED) {
                setSnackbarMessage(REJECTED_MESSAGE)
                await RegistrationReviewService.rejectReview(reviewRequestId, comment);
            }
            setShowSnackbar(true);
        }
    }

    const transformAnimal = (animal: JsonAnimal | undefined): Map<string, string> => {
        const displayPairs: Map<string, string> = new Map();
        if (!animal) return displayPairs;
        displayPairs.set("Geburtsdatum", animal.dateOfBirth.toLocaleString());
        displayPairs.set("Rasse", animal.breed);
        displayPairs.set("Farbe", animal.fiberColor);
//displayPairs.set("Züchter", baseData.breederName); //TODO implement as soon as we have ownership endpoints on the backend
        //displayPairs.set("Besitzer", baseData.ownerName); //TODO implement as soon as we have ownership endpoints on the backend
        return displayPairs;
    }

    return reviewRequest ? (
        <FlexStack>
            <TabularTextCard title={AnimalService.getAnimalFullName(animal)} displayPairs={transformAnimal(animal)}/>
            <ExistingRegistrationsCard existingRegistrations={registrations}/>
            <ReviewSimilaritiesCard reviewId={reviewRequestId} reviewedAnimal={animal} similarAnimals={similarAnimals}/>
            <PedigreeCard animal={animal} />
            <CommentCard onCommentChange={setComment}/>
            <Box display="flex" justifyContent="flex-end">
                <Button onClick={() => handleAcceptReject(OUTCOME_REJECTED)}>ablehnen</Button>
                <Button onClick={() => handleAcceptReject(OUTCOME_ACCEPTED)} variant="contained">akzeptieren</Button>
            </Box>
            <OnCloseNavigationSnackbar message={snackbarMessage} triggerSnackbar={showSnackbar} expandedRoute={RouteService.REGISTRY_ANIMAL_REVIEW_LIST}/>
        </FlexStack>
    ) : null;
};

export default AnimalReview
