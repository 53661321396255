// @flow
import * as React from 'react';
import {useEffect, useState} from 'react';
import Widget, {SortDirection} from "../../Common/widget";
import TreatmentFilterDialog, {TreatmentFilterOptions} from "./treatment-list-filter-dialog";
import TreatmentType from "../../../api/dtos/treatment-type";
import {TreatmentListJson} from "../../../api/generated/medical-rest";
import RouteService from "../../../services/route-service";
import {useHistory} from "react-router-dom";
import {TreatmentEditorLocationState} from "../../../pages/treatment-editor";
import {SortConfiguration, SortFunction, TreatmentListProps} from './treatment-list-types';
import {PaginatedTreatmentList} from "./paginated-treatment-list";


const sortFunctions: { [fieldName: string]: SortFunction } = {
    "Datum": (a: TreatmentListJson, b: TreatmentListJson) => a.timestamp.toMillis() - b.timestamp.toMillis() //TODO i18n the key!!
};

const filterTreatments = (treatments: TreatmentListJson[], options: TreatmentFilterOptions | null): TreatmentListJson[] => {
    if (!options) return treatments;
    if (options.includedTypes && options.includedTypes.length > 0) {
        const includedTypes: string[] = options.includedTypes;
        return treatments.filter(t => includedTypes.includes(t.type));
    }
    return treatments;
};

const sortTreatments = (treatments: TreatmentListJson[], fkt: SortFunction): TreatmentListJson[] => {
    return fkt ? treatments.sort(fkt) : treatments;
}

const getSortFunction = (sortFunctionName: string, direction: SortDirection) => {
    const fkt = sortFunctions[sortFunctionName];
    return direction === SortDirection.ASC ?
        (a: TreatmentListJson, b: TreatmentListJson) => fkt(a, b) :
        (a: TreatmentListJson, b: TreatmentListJson) => -fkt(a, b);
}

const TreatmentList: React.FC<TreatmentListProps> = (props) => {
    const history = useHistory();

    const [sort, setSort] = useState<SortConfiguration>({name: "Datum", direction: SortDirection.DESC})
    const [filterDialogOpen, setFilterDialogOpen] = React.useState<boolean>(false);
    const [treatmentFilterOptions, setTreatmentFilterOptions] = React.useState<TreatmentFilterOptions | null>(null);
    const [displayTreatments, setDisplayTreatments] = React.useState<TreatmentListJson[]>([])

    useEffect(() => {
        const filteredAndSorted = sortTreatments(filterTreatments(props.treatments, treatmentFilterOptions), getSortFunction(sort.name, sort.direction));
        setDisplayTreatments(filteredAndSorted);
    }, [props.treatments, sort, treatmentFilterOptions]);


    const handleFilterClick = () => setFilterDialogOpen(true);


    const handleFilterDialogClose = (filterOptions: TreatmentFilterOptions | null) => {
        setFilterDialogOpen(false);
        setTreatmentFilterOptions(filterOptions);
    };

    const handleTreatmentClick = (treatment: TreatmentListJson) => {
        navigateToTreatmentEditor(treatment.type as TreatmentType, treatment);
    };

    const navigateToTreatmentEditor = (treatmentType: TreatmentType, treatment?: TreatmentListJson) => {
        const locationState: TreatmentEditorLocationState | undefined = treatment ? {treatment} : undefined;
        history.push(
            {
                pathname: RouteService.expand(RouteService.TREATMENT_EDIT, {animalId: props.animal.id}),
                search: `?treatmentType=${treatmentType}&panonId=${props.animal.panonIdentifier.id}`,
                state: locationState
            }
        )
    }

    return <Widget className="treatments-widget" displayName="Behandlungen" filterable
                   sortableFields={Object.keys(sortFunctions)}
                   onSortUpdate={(name: string, direction: SortDirection) => setSort({name, direction})}
                   onFilterClick={handleFilterClick}
                   cardMenuEntries={[
                       {actionName: "Neue Medikation", actionFunction: () => navigateToTreatmentEditor(TreatmentType.otherMedication)},
                       {actionName: "Gewicht erfassen", actionFunction: () => navigateToTreatmentEditor(TreatmentType.weighing)},
                       {actionName: "Bodyscore eintragen", actionFunction: () => navigateToTreatmentEditor(TreatmentType.bodyscore)},
                       {actionName: "Sonstige Behandlung erfassen", actionFunction: () => navigateToTreatmentEditor(TreatmentType.other)}
                   ]}>

        <TreatmentFilterDialog open={filterDialogOpen} onClose={handleFilterDialogClose}/>
        {displayTreatments.length
            ? <PaginatedTreatmentList treatments={displayTreatments} onTreatmentDelete={props.onTreatmentDelete}
                                      onTreatmentClick={handleTreatmentClick}/>
            : 'Noch keine Behandlungen vorhanden.'}
    </Widget>;
};

export default TreatmentList;
