import React, {useState} from 'react';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import {Button, DialogActions, DialogContent, SxProps} from "@mui/material";
import TreatmentType from "../../../api/dtos/treatment-type";
import TreatmentTypeSelect from "../Common/treatment-type-select";


export interface TreatmentFilterDialogProps {
    open: boolean;
    onClose: (filterOptions: TreatmentFilterOptions | null) => void;
}

export interface TreatmentFilterOptions {
    includedTypes?: TreatmentType[];
    timestamp?: Date;
    note?: string;
    drug?:string;
}

const styles: Record<string, SxProps> = {
    button: {
        margin: 2
    },
    dialogPaper: {
        padding: 1
    }
}

const TreatmentFilterDialog = (props: TreatmentFilterDialogProps) => {
    const {onClose, open} = props;

    const [treatmentType, setTreatmentType] = useState(TreatmentType.other);

    const handleClose = () => onClose(null);
    const handleFilterApply = () => onClose({includedTypes: [treatmentType]});

    return (
        <Dialog maxWidth="xs" fullWidth={true} onClose={handleClose} aria-labelledby="label-assignment-dialog-title"
                open={open}>
            <DialogTitle id="simple-dialog-title">Behandlungen filtern</DialogTitle>
            <DialogContent>
                <TreatmentTypeSelect type={treatmentType} availableOptions={Object.values(TreatmentType)} label="Behandlungsart" onTypeChange={setTreatmentType} />
            </DialogContent>
            <DialogActions>
                <Button sx={styles.button} variant="contained" color="primary" onClick={handleClose}>Abbrechen</Button>
                <Button sx={styles.button} variant="contained" color="primary" onClick={handleFilterApply}>Anwenden</Button>
            </DialogActions>
        </Dialog>
    );
};

export default TreatmentFilterDialog;
